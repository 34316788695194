@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_panel.pcss';

.lia-panel {
  &-standard,
  &-divider {
    @include panel-defaults();

    .lia-panel {
      &-header,
      &-footer,
      &-body {
        padding: $lia-panel-spacing-md $lia-panel-spacing-lg;

        @media (--lia-breakpoint-down-sm) {
          padding-left: $lia-panel-spacing-sm;
          padding-right: $lia-panel-spacing-sm;
        }
      }
    }
  }

  &-size-compact {
    .lia-panel-header,
    .lia-panel-footer {
      padding: $lia-panel-spacing-sm;
    }
    .lia-panel-body {
      padding: $lia-panel-spacing-md $lia-panel-spacing-sm;
    }
  }

  &-body {
    &:not(:first-child) {
      padding-top: 0;
    }
    &:not(:last-child) {
      padding-bottom: 0;
    }
  }

  &-footer {
    font-size: var(--lia-bs-font-size-sm);
  }

  &-divider {
    .lia-panel {
      &-header {
        border-bottom: 1px solid var(--lia-panel-border-color);
        margin-bottom: $lia-panel-spacing-sm;
      }
      &-footer {
        border-top: 1px solid var(--lia-panel-border-color);
        margin-top: $lia-panel-spacing-sm;
      }
    }
  }

  &-bubble,
  &-spaced {
    .lia-panel {
      &-header {
        padding: $lia-panel-spacing-md 0;
      }
      &-footer {
        margin-top: 5px;
        padding: $lia-panel-spacing-md $lia-panel-spacing-sm;
        background-color: var(--lia-panel-bg-color);
        border-radius: var(--lia-panel-border-radius);
      }
    }
  }

  &-bubble {
    .lia-panel-body {
      padding: $lia-panel-spacing-sm;
      @include panel-defaults();
    }
  }
}
